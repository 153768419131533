import { ActionFunctionArgs, redirect } from 'react-router-dom';
import apiInstance, { ServerErrorResponse } from 'services/api';
import { Festival, festivalsEndpoints } from 'pages/festivals/types';
import { showError } from 'helpers/alerts';

export async function createFestival({ request }: ActionFunctionArgs): Promise<Response> {
  const formData = await request.formData();
  const name = formData.get('name');
  const festivalInstagramHandle = formData.get('festivalInstagramHandle');
  const startDate = formData.get('startDate');
  const endDate = formData.get('endDate');

  try {
    const festival: Festival = await apiInstance.post(festivalsEndpoints.create, {
      name,
      festivalInstagramHandle,
      startDate,
      endDate,
    });

    return redirect(`/festivals/${festival.id}`);
  } catch (err) {
    showError(err as ServerErrorResponse);
    return Promise.reject(err);
  }
}
