import { auth } from 'constants/endpoints';
import routes from 'constants/routes';
import { ACCESS_TOKEN } from 'constants/storage';
import { LoginResponce } from 'types/auth';
import { useNavigate } from 'react-router-dom';
import apiInstance, { ServerErrorResponse } from 'services/api';
import { Container, Grid, TextField, Typography } from '@mui/material';
import { Button } from 'components/Button';
import { object, string, InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import './login.scss';

const createPasswordValidationSchema = object({
  email: string().required('Email is required').email(),
  password: string().required('Password is required').min(8, 'Password length should be not less than 8'),
});
type LoginData = InferType<typeof createPasswordValidationSchema>;

export function Login() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginData>({ resolver: yupResolver(createPasswordValidationSchema) });

  const onSubmit: SubmitHandler<LoginData> = async data => {
    try {
      const res: LoginResponce = await apiInstance.post(auth.signin, data);

      localStorage.setItem(ACCESS_TOKEN, res.accessToken);
      navigate(routes.Events);
    } catch (err) {
      const error = err as ServerErrorResponse;
      setError('root', { message: error.message.join() });
    }
  };

  return (
    <Container className="horizontal-center full-size">
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container item spacing={2} xs={12} md={4} xl={3}>
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h4">Login</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="input"
              fullWidth
              type="email"
              {...register('email')}
              helperText={errors.email?.message}
              error={!!errors.email}
              placeholder="Email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="input"
              fullWidth
              type="password"
              {...register('password')}
              helperText={errors.password?.message}
              error={!!errors.password}
              placeholder="Password"
            />
          </Grid>
          <Grid item xs={12}>
            {errors.root && <Typography variant="caption" color="error">{errors.root?.message}</Typography>}
            <Button fullWidth type="submit" variant="contained">Log in</Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
