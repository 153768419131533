import routes from 'constants/routes';
import { useRouteError } from 'hooks/routes';
import { Navigate } from 'react-router-dom';

export const ErrorComponent = () => {
  const error = useRouteError();

  if (error.statusCode === 401) {
    return <Navigate to={routes.Login} />;
  }

  return (
    <div style={{
      width: '100vh',
      height: '100vw',
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      Error happened
      {error.message}
    </div>
  );
};
