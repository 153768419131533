import { RouteObject } from 'react-router-dom';
import { ErrorComponent } from 'components/ErrorComponent';
import { Festivals, festivalsPageLoader } from './Festivals';
import { FestivalDetails, festivalDetailPageLoader } from './FestivalDetails';
import { NewFestival } from './NewFestival';
import { createFestival } from './actions/createFestival';
import { editFestival } from './actions/editFestival';

export const festivalsRoute: RouteObject = {
  children: [
    {
      path: 'festivals',
      element: <Festivals />,
      errorElement: <ErrorComponent />,
      loader: festivalsPageLoader,
    },
    {
      path: 'festivals/new',
      element: <NewFestival />,
      errorElement: <ErrorComponent />,
      action: createFestival,
    },
    {
      path: 'festivals/:id',
      element: <FestivalDetails />,
      errorElement: <ErrorComponent />,
      loader: festivalDetailPageLoader,
    },
    {
      path: 'festivals/:id/edit',
      element: <NewFestival />,
      errorElement: <ErrorComponent />,
      loader: festivalDetailPageLoader,
      action: editFestival,
    },
  ],
};
