import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Login } from 'pages/login';
import { Events, eventsPageLoader } from 'pages/events';
import routes from 'constants/routes';
import SideBar from 'components/sidebar/SideBar';
import { ErrorComponent } from 'components/ErrorComponent';
import { EventDetail } from 'pages/eventDetail';
import { eventDetailPageLoader } from 'pages/eventDetail/EventDetail';
import { CreatePassword } from 'pages/createPassword';
import { festivalsRoute } from 'pages/festivals';
import { CelebrityDetails, celebrityDetailsLoader } from 'pages/celebrityDetails';
import { Fb, fbPageLoader } from 'pages/fb';
import { Celebrities, celebritiesPageLoader } from 'pages/celebrities';
import { Metrics, metricsPageLoader } from 'pages/metrics';
import { PublicOnlyRoute } from './PublicOnlyRoute';
import { PrivateRoute, privateRouteLoader } from './PrivateRoute';

const router = createBrowserRouter([
  {
    element: <PublicOnlyRoute />,
    children: [
      {
        path: routes.Login,
        element: <Login />,
      },
      {
        path: routes.CreatePassword,
        element: <CreatePassword />,
      },
    ],
  },
  {
    element: <PrivateRoute />,
    loader: privateRouteLoader,
    errorElement: <ErrorComponent />,
    children: [
      {
        element: <SideBar />,
        children: [
          {
            path: routes.Events,
            element: <Events />,
            errorElement: <ErrorComponent />,
            loader: eventsPageLoader,
          },
          {
            path: routes.EventDetail,
            element: <EventDetail />,
            errorElement: <ErrorComponent />,
            loader: eventDetailPageLoader,
          },
          {
            path: routes.Celebrities,
            element: <Celebrities />,
            errorElement: <ErrorComponent />,
            loader: celebritiesPageLoader,
          },
          {
            path: routes.CelebrityDetail,
            element: <CelebrityDetails />,
            errorElement: <ErrorComponent />,
            loader: celebrityDetailsLoader,
          },
          {
            path: routes.fb,
            element: <Fb />,
            errorElement: <ErrorComponent />,
            loader: fbPageLoader,
          },
          festivalsRoute,
          {
            path: routes.Metrics,
            element: <Metrics />,
            errorElement: <ErrorComponent />,
            loader: metricsPageLoader,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={routes.Events} replace />,
  },
]);

export default router;
