import { useState } from 'react';
import apiInstance from 'services/api';
import { useLoaderData } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { metrics } from 'constants/endpoints';
import './metrics.scss';
import { PhotoMetrics } from 'types/photo-metrics';

export const metricsPageLoader = () => apiInstance.get(metrics.getPhotoMetrics(30));

function sortMetrics(input: PhotoMetrics[]) {
  input.sort((a, b) => ((b.signed + b.uploaded) - (a.signed + a.uploaded)));
}

export function Metrics() {
  const initial = useLoaderData() as PhotoMetrics[];
  sortMetrics(initial);

  const [priorDays, setPriorDays] = useState(14);
  const [photoMetrics, setPhotoMetrics] = useState(initial);

  const options = [
    { value: 2, label: '2' },
    { value: 7, label: '7' },
    { value: 14, label: '14' },
    { value: 30, label: '30' },
    { value: 60, label: '60' },
    { value: 90, label: '90' },
  ];

  const handleChange = async (event: React.FormEvent<HTMLSelectElement>) => {
    const currentVal = parseInt(event.currentTarget.value, 10);
    setPriorDays(currentVal);

    const newData: PhotoMetrics[] = await apiInstance.get(metrics.getPhotoMetrics(currentVal));
    sortMetrics(newData);
    console.log('updated metrics', newData);
    setPhotoMetrics(newData);
  };

  return (
    <div>
      <div className="select-days">
        Showing data for the past {' '}
        <select value={priorDays} onChange={handleChange}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {' '} days
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 250 }} aria-label="simple table">
          <TableHead className="header">
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">Photos Uploaded</TableCell>
              <TableCell align="right">Photos Signed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {photoMetrics.map(row => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="right">{row.uploaded}</TableCell>
                <TableCell align="right">{row.signed}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
