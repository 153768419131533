/* eslint-disable jsx-a11y/label-has-associated-control */
import { Avatar } from '@mui/material';
import defaultAvatar from 'assets/img/avatar.png';
import { Button } from './Button';

type Props = {
  currentImage: string | null;
  onSelect: (img: File) => void;
  fileName?: string
}

// eslint-disable-next-line arrow-body-style
export const ImageUploader: React.FC<Props> = ({ currentImage, onSelect, fileName }) => {
  return (
    <>
      <Avatar
        src={currentImage || defaultAvatar}
        variant="rounded"
        sx={{ width: '100%', height: 'unset' }}
      />
      <label>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={e => {
            if (e.target.files?.[0]) {
              onSelect(e.target.files?.[0]);
            }
          }}
        />
        <Button component="span" variant="contained" className="mt12">
          Upload
          {` ${fileName}`}
        </Button>
      </label>
    </>
  );
};
