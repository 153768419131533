import { LoaderFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom';
import apiInstance from 'services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { Avatar,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography } from '@mui/material';
import defaultAvatar from 'assets/img/avatar.png';
import { Block } from 'components';
import { Festival, festivalsEndpoints } from 'pages/festivals/types';
import { CelebrityPreview } from 'types/celebrity';
import { SuggestionsSearchSelect } from 'components/SuggestionsSearchSelect';
import { useEffect, useState } from 'react';

const formatUtcDate = (date: Date | string) => moment(date).utc().format('MM/DD/yyyy HH:mm');

export const festivalDetailPageLoader = async ({ params }: LoaderFunctionArgs) => {
  if (!params?.id) {
    throw new Error('Invalid Id');
  }
  const festival = await apiInstance.get(festivalsEndpoints.getFestivalDetails(params.id));

  return {
    festival,
  };
};

type LoadedData = {
  festival: Festival,
  avaliableCelebrities: CelebrityPreview,
}

const load = (festivalId: string | number, query: string) => apiInstance.get(festivalsEndpoints.getCelebrities(festivalId, query));

export function FestivalDetails() {
  const navigate = useNavigate();
  const { festival } = useLoaderData() as LoadedData;

  const [selectOptions, setSelectOptions] = useState<CelebrityPreview[]>([]);

  const onChange = async (query: string) => {
    const data = (await load(festival.id, query)) as unknown as CelebrityPreview[];
    setSelectOptions(data);
  };

  useEffect(() => {
    onChange('');
  }, []);

  async function onAddCelebrity(id: number) {
    await apiInstance.patch(festivalsEndpoints.assign(festival.id, id));
    navigate(0);
  }

  async function onRemoveCelebrity(id: number) {
    await apiInstance.patch(festivalsEndpoints.unassign(festival.id, id));
    navigate(0);
  }

  return (
    <Grid className="event-details" container spacing={2}>
      <Grid item xs={12}>
        <Block className="main-info">
          <Grid item xs={12} md={6}>
            <Avatar
              variant="rounded"
              className="avatar"
              src={festival.festivalPhoto?.url || defaultAvatar}
              sx={{ width: '100%', height: 'unset' }}
            />
          </Grid>
          <Grid item xs={12} md={12} className="list">
            <div className="full-width">
              <Grid item className="row-between list-item" xs={12}>
                <Typography variant="h3">{festival.name}</Typography>
                <Chip
                  className="status desktop-only"
                  label={festival.status}
                  color="info"
                />
              </Grid>
              <Divider className="divider" />
              <Grid item className="row-between list-item mobile-only" xs={12}>
                <Typography variant="body1">Status</Typography>
                <Chip
                  className="status"
                  label={festival.status}
                  color="info"
                />
              </Grid>
              <Grid item className="row-between list-item" xs={12}>
                <Typography variant="body1">Start Date</Typography>
                <Typography variant="body1" fontWeight="bold">{formatUtcDate(festival.startDate)}</Typography>
              </Grid>
              <Grid item className="row-between list-item" xs={12}>
                <Typography variant="body1">End Date</Typography>
                <Typography variant="body1" fontWeight="bold">{formatUtcDate(festival.endDate)}</Typography>
              </Grid>
              {festival.sponsorName && (
                <Grid item className="row-between list-item" xs={12}>
                  <Typography variant="body1">Sponsor</Typography>
                  <Typography variant="body1" fontWeight="bold">{festival.sponsorName}</Typography>
                </Grid>
              )}
              {festival.isAgeSensitive !== undefined && (
                <Grid item className="row-between list-item" xs={12}>
                  <Typography variant="body1">Age sensitive</Typography>
                  <Typography variant="body1" fontWeight="bold">{festival.isAgeSensitive ? 'Yes' : 'No'}</Typography>
                </Grid>
              )}
              {festival.sponsorInstagramHandle && (
                <Grid item className="row-between list-item" xs={12}>
                  <Typography variant="body1">Sponsor Instagram</Typography>
                  <Typography variant="body1" fontWeight="bold">
                    <a href={`https://instagram.com/${festival.sponsorInstagramHandle}`} target="_blank" rel="noreferrer">
                      {festival.sponsorInstagramHandle}
                    </a>
                  </Typography>
                </Grid>
              )}
              {festival.festivalLogo && (
                <Grid item className="row-between list-item" xs={12}>
                  <Typography variant="body1">Festival logo</Typography>
                  <Avatar
                    variant="rounded"
                    src={festival.festivalLogo?.url || defaultAvatar}
                    sx={{ width: 'auto', height: '100px' }}
                  />
                </Grid>
              )}
              {festival.sponsorLogo && (
                <Grid item className="row-between list-item" xs={12}>
                  <Typography variant="body1">Sponsor logo</Typography>
                  <Avatar
                    variant="rounded"
                    src={festival.sponsorLogo?.url || defaultAvatar}
                    sx={{ width: 'auto', height: '100px' }}
                  />
                </Grid>
              )}
            </div>
          </Grid>
        </Block>
      </Grid>
      <Grid item xs={12}>
        <Block>
          <SuggestionsSearchSelect
            onChange={onChange}
            data={selectOptions}
            renderItem={c => (
              <MenuItem key={c.id} onClick={() => onAddCelebrity(c.id)}>
                <ListItemAvatar>
                  <Avatar alt={c.name} src={c.photoUrl} />
                </ListItemAvatar>
                <ListItemText primary={c.name} secondary={c.genre} />
              </MenuItem>
            )}
          />
          <List>
            {
              festival.celebrities?.map(c => (
                <ListItem key={c.id}>
                  <ListItemAvatar>
                    <Avatar alt={c.name} src={c.photoUrl} />
                  </ListItemAvatar>
                  <ListItemText primary={c.name} secondary={c.genre} />
                  <Tooltip title="Delete">
                    <IconButton onClick={() => onRemoveCelebrity(c.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))
            }
          </List>
        </Block>
      </Grid>
    </Grid>
  );
}
