import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { Card } from '../card/Card';

type Props = {
  id: number;
  img: string;
  title: string;
}

export const FestivalCard: React.FC<Props> = ({ id, img, title }) => (
  <Link className="event-card" to={routes.getFestivalDetail(id)}>
    <Card img={img} title={title} />
  </Link>
);
