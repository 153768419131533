import { EventPreview } from 'types/event';
import { useLoaderData } from 'react-router-dom';
import { EventCard } from 'components';
import { events as eventsAPI } from 'constants/endpoints';
import apiInstance from 'services/api';
import { Grid } from '@mui/material';

export const eventsPageLoader = () => apiInstance.get(eventsAPI.getEvents);

export function Events() {
  const events = useLoaderData() as EventPreview[];

  return (
    <Grid className="event-detail" container spacing={2}>
      {events?.map(e => (
        <Grid key={e.id} item xs={12} md={4} xl={2}>
          <EventCard key={e.id} title={e.name} img={e.celebrity.photoUrl} id={e.id} celebrityName={e.celebrity.name} />
        </Grid>
      ))}
    </Grid>
  );
}
