export const auth = {
  me: '/admins/me',
  signup: '/auth/admins/sign-up',
  signin: '/auth/admins/sign-in',
};

export const events = {
  getEvents: '/admins/events/review',
  getEvent: (eventId: string) => `/admins/events/${eventId}`,
  updateEvent: (eventId: string) => `/admins/events/${eventId}`,
  completeEvent: (eventId: number) => `/admins/events/${eventId}/mint`,
};

export const celebrities = {
  getCelebrities: (status: number, page = 1) => `/admins/celebrities?status=${status}&page=${page}`,
  getCelebrityDetails: (celebrityId: string) => `/admins/celebrities/${celebrityId}`,
};

export const requests = {
  flagRequest: (requestId: number) => `/admins/requests/${requestId}/flag`,
  deselectReqest: (requestId: number) => `/admins/requests/${requestId}/deselect`,
};

export const fb = {
  token: '/admins/fb-token',
};

export const metrics = {
  getPhotoMetrics: (numPriorDays: number) => `/admins/photo-metrics/${numPriorDays}`,
};
