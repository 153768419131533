import { Card } from '../card/Card';

type Props = {
  img: string;
  onFlag: React.MouseEventHandler<HTMLButtonElement>;
  onDeselect: React.MouseEventHandler<HTMLButtonElement>;
  error: string[],
}

export const RequestCard: React.FC<Props> = ({ img, onFlag, onDeselect }) => (
  <Card img={img} buttonsSettings={[{ text: 'Flag request', cb: onFlag }, { text: 'Deselect request', cb: onDeselect }]} />
);
