import { Outlet, useLoaderData } from 'react-router-dom';
import { CurrentUserContext } from 'context/CurrentUserContext';
import { useMemo } from 'react';
import { Admin } from 'types/admin';
import { auth } from 'constants/endpoints';
import apiInstance from 'services/api';

export const privateRouteLoader = () => apiInstance.get<Admin>(auth.me);

export const PrivateRoute = () => {
  const currentUser = useLoaderData() as Admin;

  const context = useMemo(() => ({ currentUser }), [currentUser]);

  return (
    <CurrentUserContext.Provider value={context}>
      <Outlet />
    </CurrentUserContext.Provider>
  );
};
