import { SelectOption } from 'components/Select';
import { CelebrityPreview } from 'types/celebrity';
import { BlobImage } from 'types/common';

export type Festival = {
  id: number;
  startDate: string;
  endDate: string;
  name: string;
  festivalInstagramHandle: string;
  sponsorName: string;
  sponsorInstagramHandle: string;
  isSponsored: boolean;
  isAgeSensitive: boolean;
  status: FestivalStatus;
  celebrities: CelebrityPreview[];
  festivalPhoto: BlobImage;
  festivalLogo: BlobImage;
  sponsorLogo: BlobImage;
}

export enum FestivalStatus {
  New = 'new',
  Active = 'active',
  Completed = 'completed',
}

export const statusDropdownOptions: SelectOption[] = [
  { value: FestivalStatus.New, label: 'New' },
  { value: FestivalStatus.Active, label: 'Active' },
  { value: FestivalStatus.Completed, label: 'Completed' },
];

export enum FestivalPhotoType {
  FESTIVAL = 'festival',
  FESTIVAL_LOGO = 'festival-logo',
  SPONSOR_LOGO = 'sponsor-logo',
}

export const festivalsEndpoints = {
  getFestivals: (status: FestivalStatus) => `/admins/festivals?status=${status}`,
  getFestivalDetails: (id: string) => `/admins/festivals/${id}`,
  create: '/admins/festivals',
  update: (id: string) => `/admins/festivals/${id}`,
  updatePhoto: (id: string | number, type: FestivalPhotoType) => `/admins/festivals/${id}/upload/${type}`,
  getCelebrities: (id: string | number, query: string) => `/admins/festivals/${id}/celebrities?name=${query}`,
  assign: (festivalId: number, celebrityId: number) => `/admins/festivals/${festivalId}/assign/celebrities/${celebrityId}`,
  unassign: (festivalId: number, celebrityId: number) => `/admins/festivals/${festivalId}/un-assign/celebrities/${celebrityId}`,
};
