import React, { useEffect, useState } from 'react';
import { fb as fbAPI } from 'constants/endpoints';
import apiInstance from 'services/api';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import { useLoaderData } from 'react-router-dom';
import { FBLoginStatus, FBPermissions, FBlogout, getLoginStatus } from 'services/facebook';
import { Grid, Typography } from '@mui/material';
import { Button } from 'components/Button';
import { theme } from 'theme/theme';
import { FbTokenInfo } from '../../types/fb';
import './fb.scss';

export const fbPageLoader = () => apiInstance.get(fbAPI.token);

export function Fb() {
  const tokenInfo = useLoaderData() as FbTokenInfo;
  const [token, setToken] = useState<FbTokenInfo | undefined>(tokenInfo);
  // const [logout, setLogout] = useState(false);

  function addToken(accessToken: string, userId: string) {
    apiInstance.post<never, FbTokenInfo>(fbAPI.token, { accessToken, userId })
      .then(r => {
        setToken(r);
      });
  }

  const facebookLoginCallback = (res: ReactFacebookLoginInfo) => {
    addToken(res.accessToken, res.userID);
  };

  // try to fetch token from facebook if there is no saved token in DB
  useEffect(() => {
    if (!tokenInfo) {
      getLoginStatus().then(res => {
        // Update token when a user already logged in facebook
        if (res.status === FBLoginStatus.connected && res.authResponse.accessToken) {
          addToken(res.authResponse.accessToken, res.authResponse.userID);
        }
      });
    }
  }, []);

  function deleteToken() {
    return apiInstance.delete(fbAPI.token).then(() => {
      setToken(undefined);
    });
  }

  async function FBlogOut() {
    // eslint-disable-next-line no-restricted-globals
    const allowed = confirm('Are you shure you want to logout? New celebrities will not be able to register before the next login to facebook.');

    if (allowed) {
      try {
        await deleteToken();
        FBlogout();
      } catch (err) {
        alert(`Error during logout from facebook: ${err}`);
      }
    }
  }

  // async function facebookReLoginCallback(res: ReactFacebookLoginInfo) {
  //   await deleteToken();
  //   addToken(res.accessToken, res.userID);
  // }

  // function startLogout() {
  //   setLogout(true);
  // }

  // function stopLogout() {
  //   setLogout(false);
  // }

  // if (logout) {
  //   return (
  //     <Grid className="facebook-page logout" container spacing={2}>
  //       <Grid className="container" item xs={12} md={4}>
  //         <Typography variant="body1" fontWeight="bold">
  //           Before loggin out, please, login to another account in order not to block celebrities from registration
  //         </Typography>
  //         <div className="row buttons">
  //           <Button onClick={stopLogout} variant="contained">Cancel</Button>
  //           <FacebookLogin
  //             containerStyle={{ marginLeft: 24 }}
  //             appId="1157465434918368" // todo: get from env or from backend
  //             autoLoad
  //             fields="name"
  //             scope={FBPermissions}
  //             callback={facebookReLoginCallback}
  //             icon="fa-facebook"
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  return (
    <Grid className="facebook-page" container spacing={2}>
      <div className="column">
        {token ? (
          <>
            <Typography variant="body1" fontWeight="bold">
              Logged in as
              {' '}
              {token.name}
            </Typography>
            <div className="buttons">
              <Button onClick={FBlogOut} variant="contained">Logout</Button>
            </div>
          </>
        ) : (
          <>
            <Typography color={theme.palette.warning.main} variant="h4" fontWeight="bold">
              No facebook token was found. Celebrity registration doesn&apos;t work at this moment. Please, login to Facebook ASAP.
            </Typography>
            <div className="buttons">
              <FacebookLogin
                appId="1157465434918368" // todo: get from env or from backend
                autoLoad
                fields="name"
                scope={FBPermissions}
                callback={facebookLoginCallback}
                icon="fa-facebook"
              />
            </div>
          </>
        )}

      </div>
    </Grid>
  );
}
