import { Form, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Block, Button, Select } from 'components';
import { Grid, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { Festival, FestivalPhotoType, festivalsEndpoints, statusDropdownOptions } from 'pages/festivals/types';
import { useState } from 'react';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ImageUploader } from 'components/ImageUploader';
import apiInstance, { ServerErrorResponse } from 'services/api';
import { showError } from 'helpers/alerts';

type Form = Partial<
  Pick<Festival, 'name'
    | 'sponsorName'
    | 'sponsorInstagramHandle'
    | 'status'
    | 'startDate'
    | 'endDate'
    | 'isSponsored'
    | 'isAgeSensitive'
    | 'festivalInstagramHandle'
  >
>

export function NewFestival() {
  const data = useLoaderData() as { festival: Festival};
  const festival = data?.festival;
  const submit = useSubmit();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<Form>({
    isAgeSensitive: festival?.isAgeSensitive,
    isSponsored: festival?.isSponsored,
  });

  const onFormChange = (name: keyof Form, value: unknown) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSponsoredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const updated: Form = { ...formData, isSponsored: checked, isAgeSensitive: false };

    setFormData(updated);
  };

  const onSubmit = () => {
    // eslint-disable-next-line no-restricted-globals
    const allow = confirm(`Are you sure you want to ${!festival ? 'create' : 'edit'} this festival?`);

    if (!allow) {
      return;
    }

    const form = new FormData();
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in formData) {
      // @ts-ignore
      const value = formData[key];
      if (value) {
        form.append(key, value);
      }
    }

    submit(form, { method: 'post' });
  };

  const uploadPhoto = async (img: File, type: FestivalPhotoType) => {
    const imgFormData = new FormData();
    imgFormData.append('image', img);

    try {
      await apiInstance.post(festivalsEndpoints.updatePhoto(festival.id, type), imgFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      navigate(0);
    } catch (err) {
      showError(err as ServerErrorResponse);
    }
  };

  const onFestivalPhotoSelect = (img: File) => {
    uploadPhoto(img, FestivalPhotoType.FESTIVAL);
  };

  const onFestivalLogoSelect = (img: File) => {
    uploadPhoto(img, FestivalPhotoType.FESTIVAL_LOGO);
  };

  const onSponsorLogoSelect = (img: File) => {
    uploadPhoto(img, FestivalPhotoType.SPONSOR_LOGO);
  };

  return (
    <Block>
      <Grid className="event-details" container spacing={2}>
        {festival && (
          <Grid item xs={12} md={3} xl={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} mb={1}>
                <ImageUploader
                  currentImage={festival.festivalPhoto?.url}
                  onSelect={onFestivalPhotoSelect}
                  fileName="Festival photo"
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <ImageUploader
                  currentImage={festival.festivalLogo?.url}
                  onSelect={onFestivalLogoSelect}
                  fileName="Festival Logo"
                />
              </Grid>
              {
                formData.isSponsored && (
                  <Grid item xs={12} mb={1}>
                    <ImageUploader
                      currentImage={festival.sponsorLogo?.url}
                      onSelect={onSponsorLogoSelect}
                      fileName="Sponsor Logo"
                    />
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={festival ? 9 : 12} xl={festival ? 10 : 12}>
          <Form method="post" action="">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className="input"
                    fullWidth
                    defaultValue={festival?.name || ''}
                    type="text"
                    placeholder="Name"
                    autoComplete="off"
                    onChange={e => onFormChange('name', e.currentTarget.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="input"
                    fullWidth
                    defaultValue={festival?.festivalInstagramHandle || ''}
                    type="text"
                    placeholder="Instagram user handle"
                    autoComplete="off"
                    onChange={e => onFormChange('festivalInstagramHandle', e.currentTarget.value)}
                  />
                </Grid>

                {festival && (
                  <Grid item xs={12}>
                    <Select options={statusDropdownOptions} onChange={i => onFormChange('status', i.value)} defaultValue={festival.status} />
                  </Grid>
                )}
                {!festival && (
                  <Grid item xs={12}>
                    <DateTimePicker
                      sx={{ width: '100%' }}
                      label="Start Date"
                      // defaultValue={moment(festival?.startDate).utc()}
                      onChange={(value: moment.Moment | null) => onFormChange('startDate', value?.utc(true)?.toISOString())}
                    />
                  </Grid>
                )}
                {!festival && (
                  <Grid item xs={12}>
                    <DateTimePicker
                      sx={{ width: '100%' }}
                      label="End Date"
                      // defaultValue={moment(festival?.endDate).utc()}
                      onChange={(value: moment.Moment | null) => onFormChange('endDate', value?.utc(true)?.toISOString())}
                    />
                  </Grid>
                )}
                { festival && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={formData.isSponsored}
                          onChange={onSponsoredChange}
                        />
                      )}
                      label="Sponsored"
                    />
                    {formData.isSponsored && (
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={formData.isAgeSensitive}
                            onChange={e => onFormChange('isAgeSensitive', e.target.checked)}
                          />
                        )}
                        label="Age sensitive"
                      />
                    )}
                  </Grid>
                )}
                {festival && formData.isSponsored && (
                  <Grid item xs={12}>
                    <TextField
                      className="input"
                      fullWidth
                      defaultValue={festival?.sponsorName || ''}
                      type="text"
                      placeholder="Sponsor Name"
                      autoComplete="off"
                      onChange={e => onFormChange('sponsorName', e.currentTarget.value)}
                    />
                  </Grid>
                )}
                {festival && formData.isSponsored && (
                  <Grid item xs={12}>
                    <TextField
                      className="input"
                      fullWidth
                      defaultValue={festival?.sponsorInstagramHandle || ''}
                      type="text"
                      placeholder="Sponsor IG Handle"
                      autoComplete="off"
                      onChange={e => onFormChange('sponsorInstagramHandle', e.currentTarget.value)}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button fullWidth size="large" onClick={onSubmit} variant="contained">{!festival ? 'Create' : 'Save'}</Button>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Form>
        </Grid>
      </Grid>
    </Block>
  );
}
