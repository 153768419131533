import { FormControl, MenuItem, Select as MaterialSelect, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';

// TODO remove any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectOption = { value: any, label: string };

type Props = {
  options: SelectOption[];
  onChange?: (item: SelectOption) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  label?: string;
  parentClassName?: string;
}

export const Select: React.FC<Props> = ({
  options, onChange, label, defaultValue, parentClassName,
}) => {
  const [v, setValue] = useState(defaultValue || options[0].value);

  function change(e: SelectChangeEvent) {
    const { value } = e.target;
    setValue(value);

    const option = options.find(o => o.value === value);

    if (option && onChange) {
      onChange(option);
    }
  }

  return (
    <FormControl fullWidth className={parentClassName}>
      <MaterialSelect
        size="small"
        value={v}
        label={label}
        onChange={change}
      >
        {options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
      </MaterialSelect>
    </FormControl>
  );
};
