import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import { ACCESS_TOKEN } from 'constants/storage';
import { EventDetailHeader } from 'pages/eventDetail/EventDetailHeader';
import { OneButtonHeader } from 'components/OneButtonHeader';
import { CelebrityStatus } from 'types/celebrity';
import { FestivalStatus } from 'pages/festivals/types';
import { AdminInfo } from 'components/AdminInfo';
import './sidebar.scss';

// TODO refactor this materail code

const menuItems = [
  {
    title: 'Events',
    link: routes.Events,
  },
  {
    title: 'Celebrities',
    link: routes.getCelebrities(CelebrityStatus.Active),
  },
  {
    title: 'Festivals',
    link: routes.getFestivals(FestivalStatus.Active),
  },
  {
    title: 'Facebook management',
    link: routes.fb,
  },
  {
    title: 'Photo Metrics',
    link: routes.Metrics,
  },
];

function getPageTitle(path: string): string {
  if (matchPath(routes.Events, path)) {
    return 'Events';
  }

  if (matchPath(routes.Celebrities, path)) {
    return 'Celebrities';
  }

  if (matchPath(routes.Festivals, path)) {
    return 'Festivals';
  }

  if (matchPath(routes.fb, path)) {
    return 'Facebook';
  }

  if (matchPath(routes.Metrics, path)) {
    return 'Metrics';
  }

  return '';
}

const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{ open?: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, { shouldForwardProp: prop => prop !== 'open' })<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const pageTitle = React.useMemo(() => getPageTitle(location.pathname), [location.pathname]);

  function logOut() {
    localStorage.removeItem(ACCESS_TOKEN);
    navigate(routes.Login);
  }

  function getHeaderContent() {
    if (matchPath('/events/:id', location.pathname)) {
      return <EventDetailHeader />;
    }

    if (matchPath('/festivals', location.pathname)) {
      return <OneButtonHeader action={() => navigate('/festivals/new')} text="Create" />;
    }

    if (matchPath('/festivals/:id', location.pathname) && !matchPath('/festivals/new', location.pathname)) {
      return <OneButtonHeader action={() => navigate(`${location.pathname}/edit`)} text="Edit" />;
    }

    return null;
  }

  function onNavClick(link: string) {
    // automatically close on mobile
    if (window.outerWidth < 500) {
      setOpen(false);
    }

    navigate(link);
  }

  return (
    <Box className="sidebar" sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar className="full-width toolbar">
          <div className="row">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {pageTitle}
            </Typography>
          </div>
          {getHeaderContent()}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <AdminInfo />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map(item => (
            <ListItem key={item.link} onClick={() => onNavClick(item.link)} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={logOut}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
