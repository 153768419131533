import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFDD67',
    },
    secondary: {
      main: '#101010',
    },
    background: {
      default: '#101010',
    },
  },
});
