import { LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { celebrities as celebritiesAPI } from 'constants/endpoints';
import { Button } from 'components';
import apiInstance from 'services/api';
import { Grid, Typography } from '@mui/material';
import { Select, SelectOption } from 'components/Select';
import { getCelebrityStatusByType } from 'helpers/celebrities';
import routes from 'constants/routes';
import { CelebrityCard } from 'components/celebrityCard/CelebrityCard';
import { CelebritiesResponse, Celebrity, CelebrityStatus } from 'types/celebrity';
import { Draggable, DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'components/StrictModeDroppable';
import { useEffect, useState } from 'react';
import './celebrities.scss';

const defaultStatus = CelebrityStatus.Active;
let page = 1;

export const celebritiesPageLoader = ({ params }: LoaderFunctionArgs) => {
  const status = params.status ? +params.status : defaultStatus;
  return apiInstance.get(celebritiesAPI.getCelebrities(status, 1));
};

const filterStatuses: CelebrityStatus[] = [
  CelebrityStatus.Hidden,
  CelebrityStatus.Active,
  CelebrityStatus.Inactive,
  CelebrityStatus.New,
  CelebrityStatus.AwaitingReview,
  CelebrityStatus.AwaitingWalletApprove,
  CelebrityStatus.DeleteRequested,
];

const dropdownOptions: SelectOption[] = filterStatuses.map(status => ({ value: status, label: getCelebrityStatusByType(status) }));

function shaffle(arr: unknown[], from: number, to: number) {
  const el = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, el);
}

function sortCelebrities(c: Celebrity[]) {
  return c.sort((a, b) => a.priority - b.priority);
}

function getStatusValue(status: string | undefined) {
  return status ? +status : defaultStatus;
}

export function Celebrities() {
  const navigate = useNavigate();
  const params = useParams();
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(false);
  const celebrities = useLoaderData() as CelebritiesResponse;
  const [dragList, setDragList] = useState<Celebrity[]>(sortCelebrities(celebrities.items));
  const { status } = params;

  useEffect(() => {
    page = 1;

    setDragList(sortCelebrities(celebrities.items));

    if (celebrities.meta.totalPages > 1) {
      setNext(true);
    }
  }, [status]);

  function onFilterChange(res: SelectOption) {
    // TODO change dynamic route to query params
    navigate(routes.getCelebrities(res.value));
  }

  const onDragEnd: OnDragEndResponder = res => {
    const { source, destination } = res;

    if (source && destination) {
      shaffle(dragList, source.index, destination?.index);
      setDragList(dragList);
    }
  };

  async function switchPage() {
    console.log('calling API for page', page);
    const celebs: CelebritiesResponse = await apiInstance.get(celebritiesAPI.getCelebrities(getStatusValue(status), page));
    setDragList(sortCelebrities(celebs.items));

    setNext(celebs.meta.totalPages > page);
    setPrev(celebs.meta.currentPage > 1);
  }

  async function loadNext() {
    page += 1;
    switchPage();
  }

  async function loadPrev() {
    if (page > 1) {
      page -= 1;
    }

    switchPage();
  }

  return (
    <Grid className="celebrities-page" container>
      <Select parentClassName="status-dropdown" options={dropdownOptions} onChange={onFilterChange} defaultValue={status || defaultStatus} />
      <DragDropContext onDragEnd={onDragEnd}>
        {/* We have 1 column for screens less than 900 */}
        <StrictModeDroppable droppableId="users" direction={window.outerWidth < 900 ? 'vertical' : 'horizontal'}>
          {droppableProvided => (
            <Grid container spacing={2} {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              {
                dragList && dragList.length > 0 ? dragList?.map((c, index) => (
                  <Draggable key={c.id} draggableId={c.id.toString()} index={index}>
                    {draggableProvided => (
                      <Grid
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        key={c.id}
                        item
                        xs={12}
                        md={4}
                        xl={2}
                      >
                        <CelebrityCard key={c.id} title={c.name} img={c.photoUrl} id={c.id} />
                      </Grid>
                    )}
                  </Draggable>
                )) : <Typography variant="body1">No celebrities found</Typography>
              }
              {droppableProvided.placeholder}
            </Grid>
          )}
        </StrictModeDroppable>
      </DragDropContext>
      <div className="page-controls">
        <Button disabled={!prev} variant="contained" className="page-button" onClick={loadPrev}>Prev</Button>
        <span className="spacer" />
        <Button disabled={!next} variant="contained" className="page-button" onClick={loadNext}>Next</Button>
      </div>
    </Grid>
  );
}
