import { LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { RequestCard } from 'components';
import { events, requests as requestsEndpoints } from 'constants/endpoints';
import apiInstance, { ServerErrorResponse } from 'services/api';
import { Grid, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import routes from 'constants/routes';
import { EventWithRequests } from 'types/event';

export const eventDetailPageLoader = ({ params }: LoaderFunctionArgs) => apiInstance.get(events.getEvent(params.eventId || ''));

export function EventDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const event = useLoaderData() as EventWithRequests;
  const { requests } = event;
  const [errors, setErrors] = useState<{ [requestId: number]: string[] }>({});

  const [newEventName, setNewEventName] = useState<string>('');
  async function saveName(id: number, name: string) {
    await apiInstance.put(events.updateEvent(String(id)), {
      name,
    });

    setNewEventName('');
    navigate(routes.getEventDetail(id), { replace: true });
  }

  async function flagRequest(id: number) {
    // eslint-disable-next-line no-restricted-globals
    const allow = confirm('Are you sure you want to remove this request?');

    if (!allow) {
      return;
    }

    try {
      await apiInstance.patch(requestsEndpoints.flagRequest(id));

      if (params.eventId) {
        navigate(routes.getEventDetail(+params.eventId));
      }
    } catch (err) {
      const error = err as ServerErrorResponse;

      setErrors(prev => ({ ...prev, [id]: error.message }));
    }
  }

  async function deselectRequest(id: number) {
    // eslint-disable-next-line no-restricted-globals
    const allow = confirm('Are you sure you want to remove this request from pool?');

    if (!allow) {
      return;
    }

    try {
      await apiInstance.patch(requestsEndpoints.deselectReqest(id));

      if (params.eventId) {
        navigate(routes.getEventDetail(+params.eventId));
      }
    } catch (err) {
      const error = err as ServerErrorResponse;

      setErrors(prev => ({ ...prev, [id]: error.message }));
    }
  }

  return (
    <Grid className="event-details">
      <Grid container style={{ paddingBottom: 20 }} justifyContent="flex-end">
        <TextField
          style={{ width: 210 }}
          size="small"
          value={newEventName}
          placeholder={event.name}
          onChange={o => setNewEventName(o.target.value)}
        />
        <IconButton onClick={() => saveName(event.id, newEventName)}>
          <SaveIcon />
        </IconButton>
      </Grid>
      <Grid container spacing={2}>
        {requests?.map(r => (
          <Grid key={r.id} item xs={12} md={4} xl={2}>
            {/* TODO handle error inside RequestCard -> Card */}
            <RequestCard
              img={r.signedThumbnailUrl || r.signedUrl}
              onFlag={() => flagRequest(r.id)}
              onDeselect={() => deselectRequest(r.id)}
              error={errors[r.id]}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
