// TODO define FB
// @ts-ignore
const { FB } = window;

export enum FBLoginStatus {
  connected = 'connected',
  not_authorized = 'not_authorized',
  unknown = 'unknown',
}

// todo: re-check list of permissions
export const FBPermissions = [
  'instagram_basic',
  'instagram_manage_insights',
  'pages_read_user_content',
  'pages_show_list',
  'pages_read_user_content',
  /*
  'pages_show_list',
  'pages_manage_metadata',
  'pages_read_engagement',
  'business_management',
*/
].join(',');

export type FBAuthResponce = {
  authResponse: {
    accessToken: string;
    expiresIn: number;
    data_access_expiration_time: number;
    graphDomain: string;
    signedRequest: string;
    userID: string;
  };
  status: FBLoginStatus;
}

export function getLoginStatus(): Promise<FBAuthResponce> {
  return new Promise(resolve => {
    FB.getLoginStatus((FBRes: FBAuthResponce) => {
      resolve(FBRes);
    });
  });
}

export function FBlogout() {
  FB.logout();
}
