import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'theme/theme';
import { ToastContainer } from 'react-toastify';
import router from './routing/Router';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider fallbackElement={<p>loading...</p>} router={router} />
      <ToastContainer theme="dark" pauseOnHover />
    </ThemeProvider>
  );
}

export default App;
