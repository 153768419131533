import { Festival, FestivalStatus, festivalsEndpoints, statusDropdownOptions } from 'pages/festivals/types';
import { LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import apiInstance from 'services/api';
import { Grid } from '@mui/material';
import { Select, SelectOption } from 'components/Select';
import routes from 'constants/routes';
import { FestivalCard } from 'components/festivalCard/FestivalCard';

const defaultStatus = FestivalStatus.Active;

export const festivalsPageLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const status = url.searchParams.get('status') || defaultStatus;
  return apiInstance.get(festivalsEndpoints.getFestivals(status as FestivalStatus));
};

export function Festivals() {
  const navigate = useNavigate();
  const params = useParams();
  const festivals = useLoaderData() as Festival[];
  const { status } = params;

  function onFilterChange(res: SelectOption) {
    navigate(routes.getFestivals(res.value));
  }

  return (
    <Grid className="event-detail" container spacing={2}>
      <Select parentClassName="status-dropdown" options={statusDropdownOptions} onChange={onFilterChange} defaultValue={status || defaultStatus} />
      {festivals?.map(f => (
        <Grid key={f.id} item xs={12} md={4} xl={2}>
          <FestivalCard key={f.id} title={f.name} img={f.festivalPhoto?.thumbnail} id={f.id} />
        </Grid>
      ))}
    </Grid>
  );
}
