import styled from '@emotion/styled';
import { Grid, TextField } from '@mui/material';
import { ReactNode, useCallback, useEffect, useState } from 'react';

type Props<T> = {
  onChange: (value: string) => void;
  data: T[];
  renderItem: (i: T) => ReactNode;
}

const Listbox = styled('div')(() => ({
  width: '100%',
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: '#121212',
  overflow: 'auto',
  maxHeight: 200,
  border: '1px solid rgba(0,0,0,.25)',
}));

export function SuggestionsSearchSelect<T>({ data, renderItem, onChange }: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement>();

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref && !ref.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, [ref]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isOpen]);

  return (
    <Grid container style={{ position: 'relative' }} ref={r => r && setRef(r)}>
      <Grid xs={12} item>
        <TextField
          autoComplete="off"
          onFocus={() => setIsOpen(true)}
          onChange={e => onChange(e.currentTarget.value)}
          fullWidth
        />
      </Grid>
      {isOpen
        && (
          <Grid xs={12} item>
            <Listbox>
              {
                data?.map(renderItem)
              }
            </Listbox>
          </Grid>
        )}
    </Grid>
  );
}
