const routes = {
  Login: '/login',
  CreatePassword: '/create-password',
  Events: '/events',
  EventDetail: '/events/:eventId',
  Celebrities: '/celebrities/:status',
  CelebrityDetail: '/celebrity-details/:celebrityId',
  Festivals: '/festivals',
  FestivalNew: '/festivals/new',
  FestivalDetails: '/festivals/:festivalId',
  Metrics: '/metrics',

  getCelebrities: (status: number) => `/celebrities/${status}`,
  getCelebrityDetail: (id: number) => `/celebrity-details/${id}`,
  getEventDetail: (eventId: number) => `/events/${eventId}`,
  getFestivals: (status: string) => (status ? `/festivals?status=${status}` : '/festivals'),
  getFestivalDetail: (id: number) => `/festivals/${id}`,

  fb: '/fb',
};

export default routes;
