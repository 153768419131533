import { Avatar, Chip, Divider, Grid, Typography } from '@mui/material';
import { Block, Button } from 'components';
import { saveAs } from 'file-saver';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Celebrity, CelebrityStatus } from 'types/celebrity';
import { getCelebrityStatusByType, getColorByCelebrityStatus } from 'helpers/celebrities';
import defaultAvatar from 'assets/img/avatar.png';
import routes from 'constants/routes';
import { approveCelebrity } from './celebrityDetailsActions';
import './celebrityDetails.scss';

export function CelebrityDetails() {
  const navigate = useNavigate();
  const celebrity = useLoaderData() as Celebrity;

  async function onApprove() {
    try {
      await approveCelebrity(celebrity.id);
      navigate(routes.getCelebrities(CelebrityStatus.AwaitingReview));
    } catch (err) {
      console.log('err', err);
    }
  }

  async function onDownloadQr() {
    saveAs(celebrity.qrCode?.url, `${celebrity.name} QR`);
  }

  return (
    <Grid className="celebrity-detail" container spacing={2}>
      <Grid item xs={12}>
        <Block className="main-info">
          <Grid item xs={12} md={6}>
            <Avatar
              variant="rounded"
              className="avatar"
              src={celebrity.photoUrl || defaultAvatar}
              sx={{ width: '100%', height: 'unset' }}
            />
          </Grid>
          <Grid item xs={12} md={12} className="list">
            <div className="full-width">
              <Grid item className="row-between list-item" xs={12}>
                <Typography variant="h3">{celebrity.name}</Typography>
                {(celebrity.status || celebrity.status === 0) && (
                  <Chip
                    className="status desktop-only"
                    label={getCelebrityStatusByType(celebrity.status)}
                    color={getColorByCelebrityStatus(celebrity.status)}
                  />
                )}
              </Grid>
              <Divider className="divider" />
              <Grid item className="row-between list-item mobile-only" xs={12}>
                <Typography variant="body1">Status</Typography>
                <Chip
                  className="status"
                  label={getCelebrityStatusByType(celebrity.status)}
                  color={getColorByCelebrityStatus(celebrity.status)}
                />
              </Grid>
              <Grid item className="row-between list-item" xs={12}>
                <Typography variant="body1">Domain</Typography>
                <Typography variant="body1" fontWeight="bold">{celebrity.domain}</Typography>
              </Grid>
              <Grid item className="row-between list-item" xs={12}>
                <Typography variant="body1">Genre</Typography>
                <Typography variant="body1" fontWeight="bold">{celebrity.genre.name}</Typography>
              </Grid>
              <Grid item className="row-between list-item" xs={12}>
                <Typography variant="body1">Phone</Typography>
                <Typography variant="body1" fontWeight="bold">{celebrity.phone}</Typography>
              </Grid>
              <Grid item className="row-between list-item" xs={12}>
                <Typography variant="body1">Email</Typography>
                <Typography variant="body1" fontWeight="bold">{celebrity.email}</Typography>
              </Grid>
              {celebrity.instagram?.accountName && (
                <>
                  <Grid item className="row-between list-item" xs={12}>
                    <Typography variant="body1">Instagram handle</Typography>
                    <a className="link" href={`https://www.instagram.com/${celebrity.instagram?.userHandle}`} target="_blank" rel="noreferrer">
                      <Typography variant="body1" fontWeight="bold">
                        {celebrity.instagram?.userHandle}
                      </Typography>
                    </a>
                  </Grid>
                  <Grid item className="row-between list-item" xs={12}>
                    <Typography variant="body1">Instagram followers</Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {celebrity.instagram?.followersCount}
                    </Typography>
                  </Grid>
                </>
              )}
            </div>
            {celebrity?.status === CelebrityStatus.AwaitingReview
              && (
                <Grid item className="row-between list-item button-row" xs={12}>
                  <Button fullWidth type="submit" variant="contained" onClick={onApprove}>Approve</Button>
                </Grid>
              )}
            {celebrity?.qrCode?.url && (
              <Grid item className="row-between list-item button-row aligned-right" xs={12}>
                <Button className="right-space" type="submit" variant="contained" onClick={onDownloadQr}>Download QR</Button>
                <CopyToClipboard text={celebrity.qrCode?.nestedUrl}>
                  <Button type="submit" variant="contained">Copy Qr Link</Button>
                </CopyToClipboard>
              </Grid>
            )}
          </Grid>
        </Block>
      </Grid>
    </Grid>
  );
}
