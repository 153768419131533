import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components';
import apiInstance, { ServerErrorResponse } from 'services/api';
import routes from 'constants/routes';
import { events } from 'constants/endpoints';

export function EventDetailHeader() {
  const navigate = useNavigate();
  const params = useParams();
  const eventId = params.eventId ? +params.eventId : undefined;

  async function tryCompleteEvent(id: number) {
    // eslint-disable-next-line no-restricted-globals
    const allow = confirm('Are you sure you want to start the minting process?');

    if (!allow) {
      return;
    }

    try {
      await apiInstance.patch(events.completeEvent(id));
      navigate(routes.Events);
    } catch (err) {
      const error = err as unknown as ServerErrorResponse;
      alert(`Error during completing event: ${error.message.join()}`);
    }
  }

  if (!eventId) {
    return null;
  }

  return (
    <Button variant="contained" className="complete-button" onClick={() => tryCompleteEvent(eventId)}>Mint NFTs</Button>
  );
}
