import { CelebrityStatus } from 'types/celebrity';

export function getCelebrityStatusByType(type: CelebrityStatus): string {
  switch (type) {
    case CelebrityStatus.New:
      return 'New';
    case CelebrityStatus.Active:
      return 'Active';
    case CelebrityStatus.AwaitingReview:
      return 'Awaiting review';
    case CelebrityStatus.AwaitingWalletApprove:
      return 'Awaiting wallet approval';
    case CelebrityStatus.Hidden:
      return 'Hidden';
    case CelebrityStatus.Inactive:
      return 'Inactive';
    case CelebrityStatus.DeleteRequested:
      return 'Delete requested';
    default:
      return '';
  }
}

export function getColorByCelebrityStatus(status: CelebrityStatus): 'success' | 'warning' | 'error' | undefined {
  switch (status) {
    case CelebrityStatus.New:
      return 'warning';
    case CelebrityStatus.Active:
      return 'success';
    case CelebrityStatus.AwaitingReview:
      return 'warning';
    case CelebrityStatus.AwaitingWalletApprove:
      return 'warning';
    case CelebrityStatus.Hidden:
    case CelebrityStatus.Inactive:
    case CelebrityStatus.DeleteRequested:
      return 'error';
    default:
      return undefined;
  }
}
