import { Navigate, Outlet } from 'react-router-dom';
import { ACCESS_TOKEN } from 'constants/storage';

export const PublicOnlyRoute = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);

  if (token) {
    return <Navigate to="" />;
  }

  return (
    <Outlet />
  );
};
