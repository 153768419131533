import MaterialCard from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import defaultAvatar from 'assets/img/avatar.png';
import './card.scss';

type Props = {
  img: string;
  title?: string;
  description?: string;
  buttonsSettings?: {
    text: string;
    cb: React.MouseEventHandler<HTMLButtonElement>;
  }[]
}

export const Card: React.FC<Props> = ({ img, title, description, buttonsSettings }) => (
  <MaterialCard className="card" sx={{ maxWidth: 345 }}>
    <CardMedia
      sx={{ height: 240, backgroundSize: 'contain' }}
      image={img || defaultAvatar}
      title="card"
    />
    {(title || description) && (
      <CardContent>
        <Typography noWrap variant="h5" component="p" style={{ textDecoration: 'none' }}>{title}</Typography>
        <Typography variant="body2" color="text.secondary">{description}</Typography>
      </CardContent>
    )}

    {buttonsSettings?.map(s => (
      <CardActions className="actions">
        <Button variant="contained" size="small" onClick={s.cb}>{s.text}</Button>
      </CardActions>
    ))}
  </MaterialCard>
);
