export enum CelebrityStatus {
  Hidden = 0,
  Active = 1,
  Inactive = 2,
  New = 3,
  AwaitingReview = 4,
  AwaitingWalletApprove = 5,
  DeleteRequested = 6,
}

export type InstagramData = {
  id: number;
  followersCount: number;
  accountName: string;
  accountType: string;
  biography: string;
  profilePictureUrl: string;
  userHandle: string;
};

export type QrCode = {
  id: number;
  url: string;
  nestedUrl: string;
};

type Genre = {
  id: number;
  name: string;
};

export type Celebrity = {
  id: number;
  name: string;
  email: null;
  phone: null;
  genre: Genre;
  photoUrl: string;
  address: string;
  domain: string;
  status: CelebrityStatus;
  instagram: InstagramData;
  qrCode: QrCode;
  priority: number;
};

export type CelebrityPreview = {
  id: number;
  name: string;
  genre: string;
  photoUrl: string;
  status: CelebrityStatus;
};

export type CelebritiesResponse = {
  items: Celebrity[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: any;
};
