import { ActionFunctionArgs, redirect } from 'react-router-dom';
import apiInstance from 'services/api';
import { festivalsEndpoints } from '../types';

export async function editFestival({ request, params }: ActionFunctionArgs): Promise<Response> {
  const formData = await request.formData();

  const name = formData.get('name');
  const festivalInstagramHandle = formData.get('festivalInstagramHandle');
  const sponsorName = formData.get('sponsorName');
  const sponsorInstagramHandle = formData.get('sponsorInstagramHandle');
  const status = formData.get('status');
  const startDate = formData.get('startDate');
  const endDate = formData.get('endDate');
  const isSponsored = Boolean(formData.get('isSponsored'));
  const isAgeSensitive = Boolean(formData.get('isAgeSensitive'));

  const data = Object.fromEntries(Object.entries({
    name,
    status,
    sponsorName,
    sponsorInstagramHandle,
    startDate,
    endDate,
    isSponsored,
    isAgeSensitive,
  }).filter(([, v]) => v != null));

  data.festivalInstagramHandle = festivalInstagramHandle;

  if (!params?.id) {
    throw Error('Invalid fest id');
  }

  if (Object.keys(data).length !== 0) {
    await apiInstance.put(festivalsEndpoints.update(params.id), data);
  }

  return redirect(`/festivals/${params.id}`);
}
