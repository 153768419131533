import { celebrities } from 'constants/endpoints';
import { LoaderFunctionArgs } from 'react-router-dom';
import apiInstance, { ServerErrorResponse } from 'services/api';

export const celebrityDetailsLoader = ({ params }: LoaderFunctionArgs) => (
  apiInstance.get(celebrities.getCelebrityDetails(params.celebrityId || 'unknown-value'))
);

export async function approveCelebrity(id: number) {
  // eslint-disable-next-line no-restricted-globals
  const allow = confirm('Are you sure you want to approve this celebrity?');

  if (!allow) {
    return;
  }

  try {
    await apiInstance.patch(`/admins/celebrities/${id}/approve`);
  } catch (err) {
    const error = err as ServerErrorResponse;

    // eslint-disable-next-line no-alert
    alert(`Error processing celebrity approval: ${error.message}`);
    throw (error);
  }
}
