import axios, { AxiosError } from 'axios';
import { ACCESS_TOKEN } from 'constants/storage';

const baseConfig = {
  baseURL: process.env.REACT_APP_API_URL,
};

const apiInstance = axios.create(baseConfig);

// TODO fix TS issue
// @ts-ignore
apiInstance.interceptors.request.use(async config => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
});

apiInstance.interceptors.response.use(r => r.data, (error: AxiosError<ServerErrorResponse>) => {
  const data = error?.response?.data;

  // Return original axios error
  if (!data) {
    throw error;
  }

  if (data.statusCode === 401) {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  throw data;
});

export type ServerErrorResponse = { error: string; message: string[]; statusCode: number };

const publicApi = axios.create(baseConfig);

export default apiInstance;
export { publicApi };
